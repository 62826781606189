.apartmentframe {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.apartmentframe .apartment-wrapper {
  align-items: flex-start;
  background-color: #eeeeee;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 20px;
  position: relative;
}

.apartmentframe .apartment {
  height: 60px;
  position: relative;
  width: 60px;
}

.apartmentframe .text-wrapper {
  color: #888888;
  
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.apartmentframe .div {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
  cursor: pointer;
}

.apartmentframe .add {
  height: 20px;
  position: relative;
  width: 20px;
}

.apartmentframe .text-wrapper-2 {
  color: #0070c0;
  
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

/* ///////////////////////////////////////////////////// */
/* account setup page */

.framexx {
  align-items: flex-end;
  display: flex;
  gap: 20px;
  position: relative;
}

.framexx .add-photo-alternate-wrapper {
  align-items: center;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 30px;
  position: relative;
}

.framexx .add-photo-alternate {
  height: 48px;
  position: relative;
  width: 48px;
}

.framexx .divxx {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  justify-content: center;
  position: relative;
}

.framexx .text-wrapper {
  align-self: stretch;
  
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.framexx .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: #0000001a;
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 7.5px 12px;
  position: relative;
  width: 100%;
}

.framexx .text-wrapper-2 {
  color: #888888;
  flex: 1;
  /* font-family: "Poppins-Regular", Helvetica; */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}


/* /////////////////////////////////////////////all companies  css//////////////// */

.frameyy {
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  padding: 20px;
  position: relative;
  /* width: 1602px; */
}

.frameyy .add-photo-alternate-wrapperyy {
  align-items: center;
  border: 1px solid;
  border-color: #442a2a1a;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  /* padding: 20px; */
  position: relative;

}

.frameyy .add-photo-alternateyy {
  height: 48px;
  position: relative;
  width: 48px;
  
}

.frameyy .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.frameyy .text-wrapperyy {
  color: #000000;
  /* font-family: "Poppins-SemiBold", Helvetica; */
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frameyy .div-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.frameyy .div-3 {
  /* align-items: center; */
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.frameyy .img {
  height: 20px;
  position: relative;
  width: 20px;
}

.frameyy .text-wrapper-2 {
  color: #888888;
  /* font-family: "Poppins-Regular", Helvetica; */
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.frameyy .div-4 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.frameyy .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
}

.frameyy .arrow-forward-ios {
  height: 24px;
  position: relative;
  width: 24px;
}
