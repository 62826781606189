/* Add your custom styles here */

.page-content {
    margin: 20px;
  }
  
  .card {
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    margin-bottom: 20px;
  }
  
  .card-body {
    padding: 1.25rem;
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .table-nowrap {
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
  }
  
  .table-nowrap thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table-nowrap tbody+tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .text-end {
    text-align: right !important;
  }
  
  .text-start {
    text-align: left !important;
  }
  
  .float-end {
    float: right !important;
  }
  
  .float-start {
    float: left !important;
  }
  
